
import kebabCase from 'lodash/kebabCase';
import { mapActions, mapGetters } from 'vuex';
import {
  assets,
  liabilities,
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  pets,
  subscription,
  user,
  will,
} from '@/mixins/apollo';
export default {
  name: 'PagesAffiliateWillIndex',
  mixins: [
    assets,
    liabilities,
    beneficiaries,
    charities,
    executors,
    gifts,
    guardians,
    pets,
    subscription,
    user,
    will,
  ],
  data() {
    return {
      maxItems: 4,
      maxPeople: 2,
      maxChildren: 2,
      tour: null,
      isSubmitting: false,
      showDownloadFlaggedWillModal: false,
      reviewPromptIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('directory-person', [
      'userDetails',
      'userPartner',
      'userChildren',
    ]),
    ...mapGetters(['verified', 'willStatus', 'isFreeWillWeek']),
    ...mapGetters('orders', ['isProductPurchased']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('progress', { willProgress: 'will' }),
    ...mapGetters('cart', [
      'cartItems',
      'cartFinalPrice',
      'cartItemFinalPrice',
      'isInCart',
    ]),
    ...mapGetters('flag', ['hasActivePlanFlags', 'isPlanModuleFlagged']),
    ...mapGetters('will-tiers', [
      'hasPurchasedWillProduct',
      'isRecommendationPageAvailable',
    ]),
    willIsNotStarted() {
      return this.willStatus === 'NOT_STARTED';
    },
    willIsInProgress() {
      return this.willStatus === 'IN_PROGRESS';
    },
    willIsFlagged() {
      return this.willStatus === 'FLAGGED';
    },
    willIsApproved() {
      return this.willStatus === 'APPROVED';
    },
    willIsAwaitingApproval() {
      return this.willStatus === 'AWAITING_APPROVAL';
    },
    willFileError() {
      return !this.will?.willFileId;
    },
    subscriptionExpiredDownloadWillAlertIsVisible() {
      return (
        (this.willIsApproved || this.willIsFlagged) &&
        !this.hasValidSubscription
      );
    },
    subscriptionExpiredAlertIsVisible() {
      return (
        (this.willIsAwaitingApproval || this.willIsInProgress) &&
        !this.hasValidSubscription
      );
    },
    willIsFlaggedAlertIsVisible() {
      return this.showActivePlanFlags;
    },
    verifyEmailAlertIsVisible() {
      return (this.willIsApproved || this.willIsFlagged) && !this.verified;
    },
    autoRenewAlertIsVisible() {
      return (
        this.willIsApproved && this.hasValidSubscription && !this.hasAutoRenew
      );
    },
    invalidPaymentMethodAlertIsVisible() {
      return (
        this.willIsApproved &&
        this.hasValidSubscription &&
        this.hasAutoRenew &&
        !this.hasValidPaymentMethod
      );
    },
    readyToSubmitAlertIsVisible() {
      return (
        this.willIsInProgress &&
        this.willProgress.complete &&
        this.hasValidSubscription &&
        !this.hasActivePlanFlags
      );
    },
    anyAlertIsVisible() {
      return (
        this.subscriptionExpiredDownloadWillAlertIsVisible ||
        this.subscriptionExpiredAlertIsVisible ||
        this.willIsFlaggedAlertIsVisible ||
        this.verifyEmailAlertIsVisible ||
        this.autoRenewAlertIsVisible ||
        this.invalidPaymentMethodAlertIsVisible ||
        this.readyToSubmitAlertIsVisible
      );
    },
    downloadTooltipTextAlertIsVisible() {
      if (this.willIsAwaitingApproval) {
        return this.$t(
          'components.willDownloadButton.tooltip.willAwaitingApproval'
        );
      }
      if (this.willFileError) {
        return this.$t('components.willDownloadButton.tooltip.willFileError');
      }
      return this.$t('components.willDownloadButton.tooltip.emailNotVerified');
    },
    isLoading() {
      const { assets, beneficiaries, guardians, will } = this.$apollo.queries;
      return {
        'About Yourself': will.loading,
        Guardians: guardians.loading && will.loading,
        Executors: will.loading,
        Estate: beneficiaries.loading && will.loading,
        Gifts: will.loading,
        Assets: assets.loading && will.loading,
        Funeral: will.loading,
        Review: will.loading,
      };
    },
    isReady() {
      const isReady = {};
      this.modules.forEach((module) => {
        isReady[module.key] =
          this.willProgress.modules[module.key].decimal === 1;
      });
      return isReady;
    },
    isModuleFlagged() {
      const isModuleFlagged = {};
      this.modules.forEach((module) => {
        isModuleFlagged[module.key] =
          this.isPlanModuleFlagged(module.flagKey) &&
          (this.willIsInProgress || this.willIsFlagged);
      });
      return isModuleFlagged;
    },
    customButtonText() {
      const customButtonText = {};
      this.modules.forEach((module) => {
        if (module.name === 'Review' && !this.willIsAwaitingApproval) {
          customButtonText[module.name] = this.$t('commons.text.submit');
        }
      });
      return customButtonText;
    },
    to() {
      const to = {};
      this.modules.forEach((module) => {
        let uri = `/will/${kebabCase(module.name)}`;
        const step = module.steps[0];
        if (module.name === 'Review' && this.isRecommendationPageAvailable) {
          uri = '/will/review/recommendation';
        } else if (step?.length) {
          uri += `/${step}`;
        }

        to[module.name] = this.localePath(uri);
      });
      return to;
    },
    childrenNames() {
      const names = this.userChildren
        .slice(0, this.maxChildren)
        .map(({ fullName }) => fullName)
        .join(', ');

      return this.userChildren.length > this.maxChildren
        ? `${names} + ${this.userChildren.length - this.maxChildren} ${this.$t(
            'texts.words.more'
          )}`
        : names;
    },
    petsWithGuardiansNames() {
      const names = this.petsWithGuardians
        .slice(0, this.maxPets)
        .map(({ meta }) => meta.find(({ key }) => key === 'name').value)
        .join(', ');

      return this.petsWithGuardians.length > this.maxPets
        ? `${names} + ${this.petsWithGuardians.length - this.maxPets} ${this.$t(
            'texts.words.more'
          )}`
        : names;
    },
    guardianNames() {
      return this.guardians
        .map(({ directoryPerson }) => directoryPerson.fullName)
        .join(', ');
    },
    maxPets() {
      let maxPets = this.maxPeople;
      if (this.userChildren.length) {
        maxPets--;
      }
      return maxPets;
    },
    petsWithGuardians() {
      return this.pets.filter((pet) => {
        return pet.guardianDirectoryPerson;
      });
    },
    executorSelection() {
      const executorKey = `pages.will.executors.options.${this.willMeta.executors_option}.label`;
      return this.$te(executorKey) ? this.$t(executorKey) : '';
    },
    primaryExecutorNames() {
      const names = this.primaryExecutors.map((executor) => {
        return executor.directoryPerson.fullName;
      });
      return names.join(' & ');
    },
    backupExecutorNames() {
      const names = this.backupExecutors.map((executor) => {
        return executor.directoryPerson.fullName;
      });
      return names.join(' & ');
    },
    funeralText() {
      let text = '';
      switch (this.willMeta.funeral_type) {
        case 'burial':
          text = this.$t('pages.will.beBuried');
          break;
        case 'cremation':
          text = this.$t('pages.will.beCremated');
          break;
        case 'donate':
          text = this.$t('pages.will.donateBodyToScience');
          break;
        case 'executor':
          text = this.$t('pages.will.letExecutorDecide');
          break;
        default:
          break;
      }
      return text;
    },
    tipText() {
      if (this.willIsAwaitingApproval) {
        return this.$t('pages.will.emailWillReadyCanChangeLater');
      } else if (this.willIsApproved) {
        return this.$t('pages.will.signAndWitnessInstructions');
      }
      return null;
    },
    willStatusText() {
      if (this.showActivePlanFlags) {
        return this.$t('components.statusText.needsAttention');
      }

      switch (this.willStatus) {
        case 'NOT_STARTED':
          return this.$t('components.statusText.statusNotStarted');
        case 'IN_PROGRESS':
          return this.willProgress.complete
            ? this.$t('components.statusText.statusReadyToSubmit')
            : this.$t('components.statusText.statusInProgress');
        case 'AWAITING_APPROVAL':
          return this.$t('components.statusText.beingReviewed');
        case 'APPROVED':
          return this.$t('components.statusText.isComplete');
        default:
          return '';
      }
    },
    willStatusTooltipText() {
      if (this.showActivePlanFlags) {
        return this.$t('components.statusText.flagged');
      }

      switch (this.willStatus) {
        case 'IN_PROGRESS':
          return this.willProgress.complete
            ? this.$t(
                this.isFreeWillWeek
                  ? 'components.statusText.inProgress.fwwIsReady'
                  : 'components.statusText.inProgress.isReady'
              )
            : this.$t('components.statusText.inProgress.notReady');
        case 'AWAITING_APPROVAL':
          return this.$t('components.statusText.awaitingApproval');
        case 'APPROVED':
          return this.$t('components.statusText.approved');
        default:
          return '';
      }
    },
    showActivePlanFlags() {
      return (
        this.hasActivePlanFlags && (this.willIsInProgress || this.willIsFlagged)
      );
    },
  },
  watch: {
    will() {
      if (
        this.willIsApproved &&
        this.willMeta.show_download_tour === undefined &&
        !this.tour
      ) {
        this.showDownloadTour();
      }
    },
  },
  methods: {
    ...mapActions('application/ui', [
      'showSubmitHelperModal',
      'showSubscriptionExpiredModal',
      'showUnlockWillModal',
    ]),
    ...mapActions('will', {
      submitWillAction: 'submitWill',
      downloadWillAction: 'downloadWill',
    }),
    ...mapActions('cart', ['addToCart']),
    showReviewPrompt() {
      this.reviewPromptIsVisible = true;
    },
    hideReviewPrompt() {
      this.reviewPromptIsVisible = false;
    },
    async submitWill() {
      if (this.hasPurchasedWillProduct) {
        if (this.will.sourceWillId) {
          this.showReviewPrompt();
        } else {
          await this.resubmitWill();
        }
      } else {
        await this.purchaseWill();
      }
    },
    async resubmitWill() {
      this.isSubmitting = true;

      if (this.willMeta.notify_charities === false) {
        this.willMeta.notify_charities = null;
        await this.updateWillMeta();
      }
      try {
        const willData = await this.submitWillAction();
        if (willData) {
          await this.$updateShouldShowCharityConsent();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async purchaseWill() {
      this.isSubmitting = true;

      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Will complete',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });

      await this.refetchWill();
      if (!this.isInCart('WILL_TIER_TWO')) {
        await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      }
      await this.$router.push({
        path: this.localePath(`/checkout/cart`),
      });
      this.isSubmitting = false;
    },
    async checkout() {
      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Will complete',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });
      await this.refetchWill();
      await this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
    unlockWill() {
      if (this.hasValidSubscription) {
        this.showUnlockWillModal();
      } else {
        this.showSubscriptionExpiredModal();
      }
    },
    async unlockAccount() {
      await this.addToCart({ codes: [this.requiredUnlockProduct] });
      await this.$router.push({ path: this.localePath(`/checkout/cart`) });
    },
    showDownloadTour() {
      this.tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
          },
          classes: 'class-1 class-2',
          scrollTo: {
            behavior: 'smooth',
            block: 'center',
          },
          modalOverlayOpeningPadding: 8,
          modalOverlayOpeningRadius: 4,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 35] } }],
          },
          when: {
            show: () => {
              const currentStepElement = this.tour.currentStep.el;
              const header =
                currentStepElement.querySelector('.shepherd-header');
              const progress = document.createElement('span');
              const current =
                this.tour.steps.indexOf(this.tour.currentStep) + 1;
              progress.classList.add('shepherd-progress');
              progress.textContent = `Tip ${current} of ${this.tour.steps.length}`;
              header.insertBefore(
                progress,
                currentStepElement.querySelector('.shepherd-title')
              );
            },
          },
        },
      });
      this.tour.addStep({
        attachTo: {
          element: '#will__button--download',
          on: 'left-start',
        },
        title: this.$t('texts.sentences.welcomeBack'),
        text: this.$t('pages.will.downloadWillAfterVerification'),
        classes: 'step-1',
        buttons: [
          {
            action() {
              return this.next();
            },
            text: this.$t('forms.labels.next'),
          },
        ],
      });
      this.tour.addStep({
        attachTo: {
          element: '#navigation__button--open',
          on: 'bottom-end',
        },
        classes: 'step-2',
        title: this.$t('texts.sentences.referFriendsAndFamily'),
        text: this.$t('pages.will.discountForReferral'),
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: this.$t('forms.labels.back'),
          },
          {
            action() {
              return this.next();
            },
            text: this.$t('forms.labels.next'),
          },
        ],
      });
      this.tour.addStep({
        attachTo: {
          element: '#navigation__button--open',
          on: 'bottom-end',
        },
        classes: 'step-3',
        title: this.$t('texts.sentences.uploadSignedWill'),
        text: this.$t('pages.will.executorAccessToWill'),
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back',
          },
          {
            action() {
              return this.hide();
            },
            text: 'Finish',
          },
        ],
      });
      this.tour.start();
      this.willMeta.show_download_tour = false;
      this.updateWillMeta();
    },
  },
};
