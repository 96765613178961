
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { pets, user } from '@/mixins/apollo';
import { objectToMetaArray } from '@/utilities';

export default {
  name: 'AppWillPetPanel',
  mixins: [pets, user],
  computed: {
    ...mapFields('pet', ['fields.name', 'fields.type']),
    ...mapGetters('pet', ['id', 'fields']),
    ...mapGetters('application/ui', ['petPanelIsOpen']),
    mutation() {
      return this.id ? this.UPDATE_PET_MUTATION : this.ADD_PET_MUTATION;
    },
    variables() {
      const variables = {
        meta: objectToMetaArray(this.fields),
        willId: this.willId,
      };

      if (this.id) {
        variables.id = this.id;
      }

      return variables;
    },
  },
  methods: {
    ...mapActions('application/ui', ['hidePetPanel']),
    async update(_, { data }) {
      if (data.addPet) {
        await this.setPets([...this.pets, data.addPet]);
      } else if (data.updatePet) {
        const updatedPets = this.pets.map((pet) => {
          if (pet.id === data.updatePet.id) {
            return data.updatePet;
          }

          return pet;
        });

        await this.setPets(updatedPets);
      }
    },
  },
};
