
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from '@/utilities';
import { VAULT_ITEM_KEY, VAULT_ITEM_TYPE } from '@/utilities/constants';

const {
  SITUATION_FAMILY_CHILD,
  SITUATION_FAMILY_PARTNER,
  SITUATION_FAMILY_OTHER,
  DECISION_EXCLUSION_DEATH,
  SITUATION_FINANCIAL_ASSETS_SIZE,
  SITUATION_FINANCIAL_ASSETS_LOCATION,
  SITUATION_FINANCIAL_ASSET_TYPE,
} = VAULT_ITEM_KEY;
const {
  HAS_CHILD_WITH_PREVIOUS_PARTNER,
  HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS,
  ESTATE_BENEFICIARY,
  HAS_FAMILY_MEMBER_WITH_DISABILITY,
  HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE,
  HAS_INTERNATIONAL,
  IS_BUSINESS_OWNER,
  IS_SELF_MANAGED_PENSION_FUND_OWNER,
  HAS_FAMILY_TRUST_INTEREST,
} = VAULT_ITEM_TYPE;

export default {
  name: 'PagesAffiliateWillReviewRecommendation',
  layout: 'ruach',
  data() {
    return {
      isFeaturesOpen: false,
      isWillTierTwoUpgradeModalVisible: false,
      openingReasons: [],
    };
  },
  computed: {
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('product-prices', ['willPrice', 'willTierTwoPrice']),
    ...mapGetters('directory-person', ['userDetails']),
    ...mapGetters('user-onboarding', ['selectedVaultItemsCount']),
    ...mapGetters('vault-item', ['vaultItems', 'willHelpAssessmentVaultItems']),
    ...mapGetters('will-tiers', ['isRecommendationPageAvailable']),
    willComplexCount() {
      let total = 0;
      if (this.$ff.willTiersSliceTwo()) {
        total += this.selectedVaultItemsCount;
      }
      if (this.$ff.willTiersSliceThree()) {
        total += this.helpReasons.length;
      }
      return total;
    },
    nextSteps() {
      const steps = this.$t('pages.onboarding.introduction.next.steps');
      return steps.map((step) => ({
        ...step,
        icon: `/introduction/${step.icon}`,
      }));
    },
    recommendationReasons() {
      if (!this.$ff.willTiersSliceTwo()) {
        return [];
      }
      return this.vaultItems.reduce((reasons, { key, type, value }) => {
        if (this.recommendationReason(key, type, value)) {
          reasons.push(this.getRecommendationReason(key, type));
        }
        return reasons;
      }, []);
    },
    helpReasons() {
      if (!this.$ff.willTiersSliceThree()) {
        return [];
      }
      return this.willHelpAssessmentVaultItems
        .filter(({ helpRequired }) => helpRequired)
        .map(({ key, type }) => `${key}.${type || 'DEFAULT'}`)
        .map((key) => this.$t(`pages.will.recommendation.help.reasons.${key}`));
    },
  },
  async mounted() {
    this.getProductPrices();
    await this.loadToolAndVaultItems();
    if (!this.isRecommendationPageAvailable) {
      this.$router.push({ path: this.localePath('/will/review') });
    }
  },
  methods: {
    ...mapActions('product-prices', ['getProductPrices']),
    ...mapActions('user-onboarding', ['loadToolAndVaultItems']),
    ...mapActions('will-tiers', ['addWillTierTwoToCart']),
    ...mapActions('cart', ['addToCart']),
    formatPrice,
    toggleFeatures() {
      this.isFeaturesOpen = !this.isFeaturesOpen;
    },
    recommendationReason(key, type, value) {
      const conditions = {
        [SITUATION_FAMILY_CHILD]: HAS_CHILD_WITH_PREVIOUS_PARTNER,
        [SITUATION_FAMILY_PARTNER]: HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS,
        [DECISION_EXCLUSION_DEATH]: ESTATE_BENEFICIARY,
        [SITUATION_FAMILY_OTHER]: HAS_FAMILY_MEMBER_WITH_DISABILITY,
        [SITUATION_FINANCIAL_ASSETS_SIZE]:
          HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE,
        [SITUATION_FINANCIAL_ASSETS_LOCATION]: HAS_INTERNATIONAL,
        [SITUATION_FINANCIAL_ASSET_TYPE]: [
          IS_BUSINESS_OWNER,
          IS_SELF_MANAGED_PENSION_FUND_OWNER,
          HAS_FAMILY_TRUST_INTEREST,
        ],
      };

      return (
        conditions[key] &&
        (Array.isArray(conditions[key])
          ? conditions[key].includes(type)
          : conditions[key] === type) &&
        (value === null || value === true)
      );
    },
    getRecommendationReason(key, type) {
      const copyPrefix = `pages.onboarding.recommendation.complex.reason.${key}.${type}`;
      return {
        id: `${key}_${type}`,
        icon: `/img/icons/${this.$t(`${copyPrefix}.icon`)}`,
        headline: this.$t(`${copyPrefix}.headline`),
        description: this.$t(`${copyPrefix}.description`),
      };
    },
    isReasonOpening(reason) {
      return this.openingReasons.includes(reason.id);
    },
    toggleReason(reason) {
      const index = this.openingReasons.indexOf(reason.id);
      if (index === -1) {
        this.openingReasons.push(reason.id);
      } else {
        this.openingReasons.splice(index, 1);
      }
    },
    async continueWithWill() {
      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
      this.goToReviewPage();
    },
    async continueWithWillTierTwo() {
      await this.addWillTierTwoToCart();
      this.goToReviewPage();
    },
    goToReviewPage() {
      this.$router.push({ path: `/${this.slug}/will/review` });
    },
  },
};
