import { render, staticRenderFns } from "./CouponModal.vue?vue&type=template&id=5faad75d"
import script from "./CouponModal.vue?vue&type=script&lang=js"
export * from "./CouponModal.vue?vue&type=script&lang=js"
import style0 from "./CouponModal.vue?vue&type=style&index=0&id=5faad75d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppFormControl: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/FormControl.vue').default,AppAdminCouponProduct: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/admin/CouponProduct.vue').default,AppForm: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Form.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
