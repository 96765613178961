
import { mapGetters, mapActions } from 'vuex';
import { metaArrayToObject } from '@/utilities';
import { isSameCharity } from '@/utilities/charity';
import { beneficiaries, charities, user, will } from '@/mixins/apollo';
import {
  RELATIONSHIP_TYPE,
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
} from '@/utilities/constants';

export default {
  key(route) {
    return route.fullPath;
  },
  mixins: [beneficiaries, charities, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      VAULT_ITEM_KEY,
      VAULT_ITEM_TYPE,
      selectedContacts: [],
      selectedCharitiesAndCauses: [],
      selectedSuggestedCharities: [],
      selectedCauseIds: [],
      isHelpRequired: false,
    };
  },
  computed: {
    ...mapGetters('ruach', ['shouldShowCharityConsentPage']),
    ...mapGetters('directory-person', ['contacts', 'contactsWithRelationship']),
    ...mapGetters('charity-causes', ['causes']),
    ...mapGetters('application/affiliate', ['slug']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('will-tiers', ['isHelpAvailable']),
    displayedCauses() {
      const causeKeys = {};
      this.causes.forEach((cause) => {
        if (
          !this.willMeta.referral_charity ||
          this.selectedCauseIds.includes(cause.id)
        ) {
          causeKeys[cause.slug] = cause;
        }
      });

      this.currentBeneficiary.backup?.forEach((backup) => {
        if (backup.cause) {
          causeKeys[backup.cause.slug] = backup.cause;
        }
      });

      return Object.values(causeKeys);
    },
    showSuggestedCharities() {
      return !this.charities.length;
    },
    currentBeneficiary() {
      return this.beneficiaries.find(
        (beneficiary) => beneficiary.id === this.$route.params.id
      );
    },
    excludedContacts() {
      return [this.currentBeneficiary.directoryPerson.id];
    },
    currentIndex() {
      return this.backupQueue.findIndex(
        (beneficiary) => beneficiary.id === this.$route.params.id
      );
    },
    prevStepUrl() {
      const beneficiary = this.backupQueue[this.currentIndex - 1];
      return beneficiary
        ? `/will/estate/backup-beneficiaries/${beneficiary.id}`
        : '/will/estate/beneficiaries';
    },
    isComplete() {
      return !!(
        this.currentBeneficiary.meta.estate_backup_split &&
        (this.currentBeneficiary.meta.estate_backup_split !== 'custom' ||
          this.selectedBeneficiaries.length ||
          this.selectedSuggestedCharities.length)
      );
    },
    filteredContacts() {
      return this.contacts.filter((contact) => {
        const isCurrentBeneficiary =
          contact.id === this.currentBeneficiary?.directoryPerson?.id;
        return !isCurrentBeneficiary;
      });
    },
    possibleBeneficiaries() {
      return [this.beneficiaries, this.contacts, this.charities];
    },
    partnerContacts() {
      return this.contactsWithRelationship([RELATIONSHIP_TYPE.PARTNER]);
    },
    childContacts() {
      return this.contactsWithRelationship([RELATIONSHIP_TYPE.CHILD]);
    },
    selectedBeneficiaries() {
      return this.selectedContacts.concat(this.selectedCharitiesAndCauses);
    },
    estateBackupSplit() {
      return this.currentBeneficiary?.meta?.estate_backup_split;
    },
  },
  watch: {
    possibleBeneficiaries() {
      this.fillSelectedBeneficiaries();
    },
    estateBackupSplit() {
      this.fillSelectedBeneficiaries();
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity']),
    ...mapActions('vault-item', ['saveHelpRequiredVaultItem']),
    fillSelectedBeneficiaries() {
      if (!this.currentBeneficiary) return;

      if (
        this.currentBeneficiary.meta.estate_backup_split === 'custom' &&
        this.beneficiaries.length &&
        !this.selectedBeneficiaries.length
      ) {
        const contactIds = this.contacts.map((contact) => contact.id);
        const charityIds = this.charities.map((charity) => charity.id);
        const selectedContactIds = [];
        const selectedCharityIds = [];
        const selectedCauseIds = [];
        this.currentBeneficiary.backup.forEach(
          ({ directoryPerson, charity, cause }) => {
            if (
              !!directoryPerson &&
              directoryPerson.id !==
                this.currentBeneficiary.directoryPerson.id &&
              contactIds.includes(directoryPerson.id)
            ) {
              selectedContactIds.push(directoryPerson.id);
            }
            if (!!charity && charityIds.includes(charity.id)) {
              selectedCharityIds.push(charity.id);
            }

            if (
              this.displayedCauses.some(
                (displayedCause) => displayedCause.id === cause?.id
              )
            ) {
              selectedCauseIds.push(cause.id);
            }
          }
        );

        this.selectedContacts = selectedContactIds;
        this.selectedCharitiesAndCauses =
          selectedCharityIds.concat(selectedCauseIds);
      }
    },
    metaArrayToObject,
    updateSelectedCauseIds(value) {
      this.selectedCauseIds = value.slice();
      this.selectedCharitiesAndCauses = value;
    },
    async done() {
      const saveVaultItem = this.isHelpAvailable
        ? this.saveHelpRequiredVaultItem({
            tool: this.willHelpAssessmentTool,
            vaultItemKey: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
            vaultItemType: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_BACKUP,
            helpRequired: this.isHelpRequired,
          })
        : Promise.resolve();

      if (this.selectedSuggestedCharities.length) {
        await Promise.all(
          this.selectedSuggestedCharities.map((charity) => {
            return this.addPartnerCharity(charity);
          })
        );
        await this.refetchCharities();
        this.charities.forEach((charity) => {
          const charityMeta = metaArrayToObject(charity.meta);
          const exists = this.selectedSuggestedCharities.find(
            (selectedSuggestedCharity) => {
              return isSameCharity(selectedSuggestedCharity, charityMeta);
            }
          );
          if (exists) {
            this.selectedBeneficiaries.push(charity.id);
          }
        });
      }

      // Save estate backup split meta data.
      const estateBackupSplit =
        this.currentBeneficiary.meta.estate_backup_split;
      await this.updateBeneficiaryMeta(this.currentBeneficiary);

      switch (estateBackupSplit) {
        case 'remaining':
          await this.removeAllBackupBeneficiaries(this.currentBeneficiary);
          break;
        case 'children':
          for (const charity of this.charities) {
            await this.removeBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
          }

          for (const contact of this.contacts) {
            const contactIsPartner = this.partnerContacts.some(
              (partner) => partner.id === contact.id
            );
            const contactIsChild = this.childContacts.some(
              (child) => child.id === contact.id
            );
            if (!contactIsPartner && !contactIsChild) {
              await this.removeBackupBeneficiaryByContact(
                this.currentBeneficiary,
                contact
              );
            }
          }

          break;
        case 'custom': {
          for (const contact of this.contacts) {
            const beneficiary = this.getBackupBeneficiaryByContact(
              this.currentBeneficiary,
              contact
            );
            const isSelected = this.selectedBeneficiaries.includes(contact.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  contact,
                  null
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          for (const charity of this.charities) {
            const beneficiary = this.getBackupBeneficiaryByCharity(
              this.currentBeneficiary,
              charity
            );
            const isSelected = this.selectedBeneficiaries.includes(charity.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  charity,
                  null
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          for (const cause of this.displayedCauses) {
            const beneficiary = this.getBackupBeneficiaryByCause(
              this.currentBeneficiary,
              cause
            );
            const isSelected = this.selectedBeneficiaries.includes(cause.id);

            if (isSelected) {
              if (!beneficiary) {
                await this.addBackupBeneficiary(
                  this.currentBeneficiary,
                  cause,
                  null
                );
              }
            } else if (beneficiary) {
              await this.removeBackupBeneficiary(beneficiary);
            }
          }

          break;
        }
        default:
          break;
      }

      this.willMeta.charity_in_estate = this.charities.length
        ? 'true'
        : 'false';

      await Promise.all([this.updateWillMeta(), saveVaultItem]);

      if (estateBackupSplit === 'custom') {
        await this.$router.push({
          path: this.localePath(
            `/will/estate/backup-distribution/${
              this.backupQueue[this.currentIndex].id
            }`
          ),
        });
      } else if (this.currentIndex < this.backupQueue.length - 1) {
        // Update current beneficiary, set isBackupEstateSplitEvenly to false
        await this.updateBeneficiary(
          this.currentBeneficiary,
          this.currentBeneficiary.distribution,
          false
        );

        await this.$router.push({
          path: this.localePath(
            `/will/estate/backup-beneficiaries/${
              this.backupQueue[this.currentIndex + 1].id
            }`
          ),
        });
      } else {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Estate',
          },
        });

        await this.refetchWill();
        await this.$updateShouldShowCharityConsent();
        const path = this.localePath(
          this.shouldShowCharityConsentPage.includes('estate')
            ? '/will/charity-consent?next-module=gifts'
            : '/will/gifts'
        );
        this.$router.push({
          path,
        });
      }
    },
  },
};
