
import { pets, user, will } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillGuardiansYourPets',
  mixins: [pets, user, will],
  computed: {
    isComplete() {
      return !!(this.willMeta.has_pets === false || this.pets.length);
    },
    submitLabel() {
      return this.willMeta.has_pets
        ? this.$t('forms.labels.next')
        : this.$t('forms.labels.continueToEstate');
    },
    prevStep() {
      let to = '/will/guardians/your-children';

      if (this.willMeta.has_children) {
        if (this.willMeta.has_backup_guardian !== null) {
          to = '/will/guardians/backup';
        } else if (this.willMeta.has_primary_guardian !== null) {
          to = '/will/guardians/primary';
        }
      }

      return to;
    },
  },
  async mounted() {
    await this.getPetsData();
  },
  methods: {
    async submit() {
      if (this.willMeta.has_pets === false && this.pets.length) {
        await this.removeAllPets();

        this.willMeta.has_pet_guardians = null;
        this.willMeta.pet_care_fund = '';
        await this.updateWillMeta();
      }

      if (!this.willMeta.has_pets) {
        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'complete_module',
          props: {
            will_id: this.willId,
            module_name: 'Family',
          },
        });
      }
      const path = this.localePath(
        this.willMeta.has_pets
          ? '/will/guardians/pet-guardians'
          : '/will/estate'
      );
      this.$router.push({
        path,
      });
    },
  },
};
