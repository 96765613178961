
import { mapActions, mapGetters } from 'vuex';
import { will, subscription } from '@/mixins/apollo';

export default {
  name: 'PagesAffiliateWillReview',
  mixins: [will, subscription],
  data: () => ({
    isSubmitting: false,
    reviewPromptIsVisible: false,
    alertFlagsVisible: false,
    submittingCtaId: null,
    submittingCtaText: null,
  }),
  computed: {
    ...mapGetters(['isFreeWillWeek']),
    ...mapGetters('cart', [
      'isInCart',
      'cartItems',
      'cartFinalPrice',
      'cartItemFinalPrice',
      'cartItemPrices',
      'isFreeCart',
    ]),
    ...mapGetters('orders', ['isProcessingOrder']),
    ...mapGetters('will', { willData: 'data' }),
    ...mapGetters('progress', {
      willProgress: 'will',
      willFirstIncompleteSectionPath: 'willFirstIncompleteSectionPath',
    }),
    ...mapGetters('flag', [
      'planFlags',
      'hasActivePlanFlags',
      'activePlanFlagsCount',
    ]),
    ...mapGetters('will-tiers', ['hasPurchasedWillProduct']),
    steps() {
      return this.$t(`pages.will.review.nextSteps.steps`);
    },
    isCombinedSubmissionPurchaseFreeCart() {
      return this.$ff.combinedFreeWillCheckoutSubmission() && this.isFreeCart;
    },
    lastFinishedSectionPath() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.localePath(this.willFirstIncompleteSectionPath)
        : this.localePath('/will');
    },
    purchasedSuccessMessage() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.$t(`pages.will.review.willPurchasedAlternative`)
        : this.$t(`pages.will.review.willPurchasedMessage`);
    },
    modulesCompletedInfoMessage() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.$t(`pages.will.review.allModulesCompletedAlternative`)
        : this.$t(`pages.will.review.allModulesCompletedMessage`);
    },
    notCompletedWarnMessage() {
      return this.$ff.combinedFreeWillCheckoutSubmission()
        ? this.$t(`pages.will.review.allModulesNotCompletedAlternative`)
        : this.$t(`pages.will.review.allModulesNotCompletedMessage`);
    },
    title() {
      return this.willProgress.complete
        ? this.$t('pages.will.review.yourWillIsNearlyFinished.title')
        : this.$t('pages.will.review.completeWillBeforeReview');
    },
    ctaId() {
      if (
        this.willProgress.complete &&
        this.hasPurchasedWillAndWillTierTwoNotInCart
      ) {
        return 'resubmit';
      } else if (this.canCheckoutAndSubmit) {
        return 'submit';
      } else if (this.willProgress.complete) {
        return 'purchase';
      }
      if (this.isCombinedSubmissionPurchaseFreeCart) {
        return 'complete';
      }
      return 'continue';
    },
    ctaText() {
      if (
        this.willProgress.complete &&
        this.hasPurchasedWillAndWillTierTwoNotInCart
      ) {
        return this.$t('pages.will.review.submitNow');
      } else if (this.willProgress.complete) {
        if (this.canCheckoutAndSubmit) {
          return this.$t('commons.text.submit');
        } else {
          return this.$t('pages.will.review.submitNow');
        }
      }
      if (this.isCombinedSubmissionPurchaseFreeCart) {
        return this.$t('components.recommendedAction.completeWill');
      }
      return this.$t('pages.will.review.continue');
    },
    showSecondaryCta() {
      return !this.willProgress.complete && !this.hasPurchasedWillProduct;
    },
    secondaryCtaText() {
      return this.showAlternativeIncompleteButtons
        ? this.$t('pages.will.review.payAndCompleteLater')
        : this.$t('pages.will.review.lockInFreeWill');
    },
    secondaryCtaId() {
      return 'purchase';
    },
    flagsTitle() {
      return this.hasActivePlanFlags
        ? this.$t('pages.will.review.flags.title.outstanding', {
            flagsCount: this.activePlanFlagsCount,
          })
        : this.$t('pages.will.review.flags.title.completed');
    },
    flagsSubtitle() {
      return this.hasActivePlanFlags
        ? this.$t('pages.will.review.flags.subtitle.outstanding')
        : this.$t('pages.will.review.flags.subtitle.completed');
    },
    isCtaDisabled() {
      return (
        this.isSubmitting || this.hasActivePlanFlags || this.isProcessingOrder
      );
    },
    hasFreeWill() {
      return (
        this.$ff.combinedFreeWillCheckoutSubmission() &&
        this.isInCart('WILL') &&
        this.cartItemPrices.find(
          (cartItemWithPrice) => cartItemWithPrice.product === 'WILL'
        ).finalPrice === 0
      );
    },
    canCheckoutAndSubmit() {
      return this.hasFreeWill && this.willProgress.complete;
    },
    hasPurchasedWillAndWillTierTwoNotInCart() {
      return (
        this.hasPurchasedWillProduct &&
        !this.isInCart('WILL_TIER_TWO') &&
        !this.isProcessingOrder
      );
    },
    showAlternativeIncompleteButtons() {
      return (
        !this.willProgress.complete &&
        !this.hasPurchasedWill &&
        !this.hasFreeWill
      );
    },
  },
  async mounted() {
    if (!this.hasPurchasedWillProduct && !this.isInCart('WILL_TIER_TWO')) {
      await this.addToCart({ codes: ['WILL'], showSnackbar: false });
    }
    if (this.activePlanFlagsCount > 0) {
      this.alertFlagsVisible = true;
    }
  },
  methods: {
    ...mapActions('will', {
      submitWillAction: 'submitWill',
    }),
    ...mapActions('orders', ['getOrders', 'setIsProcessingOrder']),
    ...mapActions('cart', [
      'addToCart',
      'cardlessPayment',
      'getCartItems',
      'processFreeItems',
      'setShowSubscriptionAutoRenewalInCheckout',
    ]),
    showReviewPrompt() {
      this.reviewPromptIsVisible = true;
    },
    hideReviewPrompt() {
      this.reviewPromptIsVisible = false;
    },
    async ctaAction() {
      if (this.willProgress.complete) {
        if (this.hasFreeWill) {
          return this.freeWillCheckoutAndSubmit();
        } else if (this.hasPurchasedWillAndWillTierTwoNotInCart) {
          if (this.will.sourceWillId) {
            this.showReviewPrompt();
          } else {
            await this.submitWill();
          }
        } else {
          return await this.checkout();
        }
      } else {
        if (this.isCombinedSubmissionPurchaseFreeCart) {
          return this.goBackToCompleteWill();
        }
        return this.openWillPage();
      }
    },
    async submitWill(redirectToHome = true) {
      this.captureCtaValuesAtSubmission();
      this.isSubmitting = true;
      try {
        await this.submitWillAction();
        await this.updateWillMeta();
        await this.refetchWill();
      } catch {
        console.error('Will submission failed.');
      } finally {
        this.isSubmitting = false;
        if (redirectToHome) {
          this.$router.push({
            path: this.localePath('/will'),
          });
        }
      }
    },
    async freeWillCheckoutAndSubmit() {
      this.captureCtaValuesAtSubmission();
      this.isSubmitting = true;
      this.setIsProcessingOrder(true);

      try {
        await this.processFreeItems({
          trigger: 'Will Review',
          safelyFailSubmission: true,
        });
      } catch (err) {
        this.isSubmitting = false;
        this.setIsProcessingOrder(false);
        throw err;
      }

      this.setShowSubscriptionAutoRenewalInCheckout(true);
      this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
    async checkout() {
      const items = this.cartItems.map((item) => {
        return {
          item_id: item.product.code,
          price: this.cartItemFinalPrice(item.product.code) / 100,
          quantity: 1,
        };
      });

      this.$nuxt.$emit('sendTrackingEvent', {
        event: 'begin_checkout',
        props: {
          checkout_trigger: 'Will complete',
          currency: this.$t('texts.words.currency'),
          value: this.cartFinalPrice / 100,
          items,
        },
      });
      await this.refetchWill();
      if (this.isCombinedSubmissionPurchaseFreeCart) {
        this.$toast.addDefault({
          severity: 'warn',
          title: 'toasts.submit.willNotYetSubmitted',
          message: 'toasts.submit.completeWillForReview',
          isTranslation: true,
        });
      }
      this.$router.push({
        path: this.localePath('/checkout/cart'),
      });
    },
    goBackToCompleteWill() {
      this.$router.push({
        path: this.lastFinishedSectionPath,
      });
    },
    async resubmitWill() {
      this.captureCtaValuesAtSubmission();
      this.isSubmitting = true;

      if (this.willMeta.notify_charities === false) {
        this.willMeta.notify_charities = null;
        await this.updateWillMeta();
      }
      try {
        const willData = await this.submitWillAction();
        if (willData) {
          await this.$updateShouldShowCharityConsent();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isSubmitting = false;
      }
    },
    openWillPage() {
      this.$router.push({ path: this.localePath('/will') });
    },
    captureCtaValuesAtSubmission() {
      this.submittingCtaId = this.ctaId;
      this.submittingCtaText = this.ctaText;
    },
  },
};
